 

.login 
{
  padding: 10%;
  padding-top: 10px;
  padding-bottom: 10%;
}
.LoginFrom {
  text-align: center;
  color: white ;
  width: 500px;
}

.logoLogin  {
width: 50%;
margin-bottom: 30px;
}

