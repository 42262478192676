 
/* Header/Blog Title */
.header { 
  margin-top: 10px;
  font-size: 40px;
  text-align: center; 
  
  
}
.header h2 { 
  padding-top: 5%;font-size: clamp(40px, 10vw, 70px); 
  text-align: center;
  color: #151b3a ;
}

.style-one {
  border: 0;
  height: 1px;
  margin-bottom: 20px;
  margin-top: 2%;

  background: rgb(255, 255, 255);
  background-image: linear-gradient(to right, #132043, #CCC, #132043);
 
}

  /* Create two unequal columns that floats next to each other */
  /* Left column */
  .leftcolumn {   
    float: left;
    width: 75%;
  }

/* Right column */
.rightcolumn {
  float: left;
  width: 25%;
  padding-left: 20px;
}

/* Fake image */
.fakeimg {
  background-color: #aaa;
  width: 100%;
  margin-bottom: 30px;
}

/* Add a card effect for articles */
.card {
  background-color: white;
  padding: 20px; 
   
}
.articleText {
   background-color: rgba(255, 255, 255, 0.856);
   padding: 20px; 
   margin-top: 50px;
   margin-bottom: 60px;
}
.articleText h2::after {
  display: block;
    content: '';
   width: 10%;
   height: 2px;
   background: #151b3a;
   margin-top: 10px;
   bottom: 0;
   left: 0;
 }

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

/* Footer */
.footer {
  padding: 20px;
  text-align: center;
  background: #ddd;
  margin-top: 20px;
}

/* Responsive layout - when the screen is less than 800px wide, make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 800px) {
  .leftcolumn, .rightcolumn {   
    width: 100%;
    padding: 0;
  }
}

.video {
  text-align: center;
  margin-top: 15%;

  margin-bottom: 20%;

}

.dateh5{
  color: rgba(255, 255, 255, 0.87);
  font-size: 14px;
  font-family: sans-serif;
  text-align: right;
  padding-bottom: 10px;
  margin-bottom: 0;
  float: top ;
}

.dateh52  {
  text-align: left;
  font-size: 14px;

  padding-bottom: 10px;
  margin-bottom: 0;
} 


.textblogdiv{
 
    font-size: 20px;
    padding: 10px;
}