
    .NavLink {
        color: rgb(218, 218, 218);
        text-decoration: none;
      }
      .NavLink:hover {
         color: rgb(255, 255, 255)
      }
      .Nav {
        color: rgb(218, 218, 218);
        text-decoration: none;
      }
      .Nav:hover {
         color: rgb(255, 255, 255)
      }
      .Navbar {
        color: rgb(218, 218, 218);
        text-decoration: none;
      }
      .Navbar:hover {
         color: rgb(255, 255, 255)
      }   .a {
        color: rgb(218, 218, 218);
        text-decoration: none;
      }
      .a:hover {
         color: rgb(255, 255, 255)
      } 