.blog_form_login {
    background : #ffffffbb ;
    padding : 30px ;
    border-radius: 5%;
    box-shadow: 1px 1px 2px rgba(58, 58, 58, 0.151), 0 0 25px rgb(146, 146, 146), 0 0 5px #88888836;
    margin-top: 15%;
}

.logoLogin {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

.imgLogin {
 
    margin-top: 25% ;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
}