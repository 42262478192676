 

.nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #e9ecef; }

.nav-fill .nav-item {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar { 
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between; }
 
.navbar-light .navbar-brand {
  color: rgb(230, 8, 8); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }
 
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }
 
.ftco-navbar-light {
  background: #e9ecef !important;
  z-index: 3;
  padding: 0;  } 
  .ftco-navbar-light .navbar-brand {
    color: #000;
    text-transform: uppercase; }
    .ftco-navbar-light .navbar-brand:hover, .ftco-navbar-light .navbar-brand:focus {
      color: #000; }
    @media (max-width: 991.98px) {
      .ftco-navbar-light .navbar-brand {
        color: #fff; } }
  @media (max-width: 991.98px) {
    .ftco-navbar-light .navbar-nav {
      padding-bottom: 10px; } }
  .ftco-navbar-light .navbar-nav > .nav-item > .nav-link {
    font-size: 16px;
    font-family: 'Ubuntu', sans-serif ;
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
    padding-left: 20px;
    padding-right: 20px;
    font-weight: 400;
    margin-right: 10px;
    margin-left: 10px;
    color: #232b51 ;
    position: relative;
    opacity: 1 !important; }
    .ftco-navbar-light .navbar-nav > .nav-item > .nav-link:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      bottom: -1px;
      left: 0;
      background-color: #40bfc1;
      visibility: hidden;
      -webkit-transform: scaleX(0);
      -ms-transform: scaleX(0);
      transform: scaleX(0);
      -webkit-transition: all 0.5s ease-in-out 0s;
      -o-transition: all 0.5s ease-in-out 0s;
      transition: all 0.5s ease-in-out 0s;
      z-index: -1; }
    .ftco-navbar-light .navbar-nav > .nav-item > .nav-link:hover {
      color: #40bfc1; }
      .ftco-navbar-light .navbar-nav > .nav-item > .nav-link:hover:before {
        visibility: visible;
        background-color: #40bfc1;
        -webkit-transform: scaleX(1);
        -ms-transform: scaleX(1);
        transform: scaleX(1); }
    @media (max-width: 991.98px) {
      .ftco-navbar-light .navbar-nav > .nav-item > .nav-link {
        padding-left: 0;
        padding-right: 0;
        padding-top: .7rem;
        padding-bottom: .7rem;
        color: #232b51 ; }
        .ftco-navbar-light .navbar-nav > .nav-item > .nav-link:hover {
          color: #54b2b4 ; }
          .ftco-navbar-light .navbar-nav > .nav-item > .nav-link:hover:before {
            display: none; } }
 
    .ftco-navbar-light .navbar-nav > .nav-item .dropdown-menu .dropdown-item {
      font-size: 16px;
      font-family: 'Ubuntu', sans-serif ;
      color: #232b51  }
      .ftco-navbar-light .navbar-nav > .nav-item .dropdown-menu .dropdown-item:hover, .ftco-navbar-light .navbar-nav > .nav-item .dropdown-menu .dropdown-item:focus {
        background: #55b4b6;
        color: rgb(255, 251, 251); }
    @media (max-width: 991.98px) {
      .ftco-navbar-light .navbar-nav > .nav-item .dropdown-menu {
        display: block !important;
        background: #7fbcd646 ; 
        border-radius: 5px ; }
        .ftco-navbar-light .navbar-nav > .nav-item .dropdown-menu .dropdown-item {
          color:  #232b51  } }
  .ftco-navbar-light .navbar-nav > .nav-item.cta > a {
    color: #fff;
    background: #40bfc1;
    border-radius: 0px; }
    @media (max-width: 767.98px) {
      .ftco-navbar-light .navbar-nav > .nav-item.cta > a {
        padding-left: 15px;
        padding-right: 15px; } }
    @media (max-width: 991.98px) {
      .ftco-navbar-light .navbar-nav > .nav-item.cta > a {
        color: #fff;
        background: #40bfc1;
        border-radius: 4px; } }
  .ftco-navbar-light .navbar-nav > .nav-item.active > a {
    color: #000; }
    .ftco-navbar-light .navbar-nav > .nav-item.active > a:before {
      visibility: visible;
      background-color: #40bfc1;
      -webkit-transform: scaleX(1);
      -ms-transform: scaleX(1);
      transform: scaleX(1); }
    @media (max-width: 991.98px) {
      .ftco-navbar-light .navbar-nav > .nav-item.active > a {
        color: #40bfc1; }
        .ftco-navbar-light .navbar-nav > .nav-item.active > a:before {
          display: none; } }
  .ftco-navbar-light .navbar-toggler {
    border: none;
    color:  #232b51 !important;
    cursor: pointer;
    padding-right: 0;
    text-transform: uppercase;
    font-size: 16px;
    font-family: 'Ubuntu', sans-serif ;
    letter-spacing: .1em; }
    .ftco-navbar-light .navbar-toggler:focus {
      outline: none !important; }
  

    .parallax {
      background-image: url('./../../img/meet.jpg') ;
      background-repeat: repeat ;
      background-attachment: fixed;
      margin-top: 0px;
      padding-top: 0;
      background-size: cover;
  
  }
   
  .frit{
  
      font-size: 150%;
      font-family: 'Lato', sans-serif;
      padding: 16% ;
      align-items: center;
     text-align: center;
     color:  #232b51;
  } 
  .back{
    background-color: #57b5c54b ;
  
  }
  .savoir{
      z-index:7;
      background-color:rgba(19,32,67,0.75);
      font-family:Roboto;
      cursor:pointer;
      outline:none;
      box-shadow:none;
      box-sizing:border-box;
      -moz-box-sizing:border-box;
      -webkit-box-sizing:border-box;
  }
   
  
  .slide {
      font-size: 15px;
      font-weight: 200;
      letter-spacing: 1px;
      padding-right: 30px  ;
      padding-left: 30px  ;
      outline: 0;
      border: 1px solid rgba(0, 0, 0, 0);
      border-radius: 30px;
      cursor: pointer;
      position: relative;
      margin-top: 30px;
      margin-right: 10px;
      color: rgba(255, 245, 238, 0.925) ;
      background-color:  #232b51b4;
   
    }
    
    .slide i {
      opacity: 0;
      font-size: 20px;
      transition: 0.2s;
      position: absolute;
      right: 5px;
      top: -3px;
      transition: transform 1;
    }
    
    .slide div {
      transition: transform 0.8s;
    }
    
    .slide:hover div {
      transform: translateX(-6px);
    }
    
    .slide::after {
      content: "";
      width: 100%;
      z-index: -1;
      position: absolute;
      height: 100%;
      top: 7px;
      left: 7px;
    }
    
    .slide:hover i {
      opacity: 1;
      transform: translateX(-2px);
    } 
  
  
  
  
  .secand {
        
      background-image: url('./../../img/bullding.jpg')   ; 
      background-attachment: fixed;
      margin : 50px; 
      background-size: cover;
  }
  .secand2 {
          background-color: rgba(255, 251, 251, 0.671);
          padding-top: 20px;
          color: #232b51b4 ;
          font-size:10px;
          font-family: 'Lato', sans-serif;
    
          align-items: center;
          text-align: center;
          color:  #232b51;
  
  }
  .logov {
      width: 50% ;
      padding: 0 ;
      margin: 0;
   }
   .titre {
      
      color: #232b51 ;
      font-size: 30px;
   }
   .prog {
      text-align: justify;
      color:  #232b51;
      font-size: 15px;
   }
   .pitit_titre {
      
      color: #232b51 ;
      font-size: 23px;
      text-align: justify;
  
   }
  
   .video {
      margin: 30px;
      margin-left: 40px ;
   }
  
   .hover08 figure img {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
  }
  .hover08 figure:hover img {
    -webkit-filter: grayscale(0);
    filter: grayscale(0);
  }
  
  
  .fourpart {
   background-image : url(' ./../../../img/teckback.jpg'); 
   margin-bottom: 40px ;
   margin-top: 40px ;
   background-size: cover;
  
  }
  .inder {
      background-color : #262627af ;
      text-align : center ;
      padding: 50px;
  
  }
  
  .butn {
    font-size: 15px;
    font-weight: 200;
    letter-spacing: 1px;
    padding-right: 40px  ;
    padding-left: 40px  ;
    padding-top: 10px  ; 
    padding-bottom: 10px  ; 
  
    outline: 0;
    border: 1px solid  rgb(255, 245, 238) ;
    border-radius: 7px;
    cursor: pointer;
    position: relative;
    margin-top: 30px;
    margin-right: 10px;
    margin-left: 20px;
    margin-right: 20px;
    color: rgb(255, 245, 238) ;
    background-color: transparent ;
  
  }
  
  
  .butn:hover {
  
  
    color: #363844 ;
    background-color: rgb(255, 245, 238)  ;
  }

.backgroundSpecialite {

  background-image: url('./../../img/metting3.jpg') ;
  background-repeat: repeat ;
  background-attachment: fixed;
  margin-top: 0px;
  padding-top: 0;
  background-size: cover;


}

.navbarst {
    padding: 20px;
    font-size: 14px;
}


.backr {
  background-color: rgba(255, 255, 255, 0.703) ;
}

@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;500&display=swap');
 

.secands {
  text-align: center ;
}
.secands hr {
  height: 2px;
  text-align: center ;
  
  padding: 0;
  margin : 30PX ;
  background-color: rgb(0, 0, 0);
  border: 0; 
} 


  .secands h1 
  {
    margin-top: 30px ;
    margin-bottom: 30px ;
    font-size: 22px;
    text-align: center; 
    font-weight: 500;
    line-height: 1.46;
    color: #232b51;
    font-family: 'Ubuntu', sans-serif ;

  }
  .secands h1::after 
  {
  	content: '' ;
  	background: #232b51 ;
  	display: block;
  	height: 3px ;
  	width: 10% ;
  	margin: 20px auto 5px ; 

  	  }

.secands p {
text-align: center ;
font-size: 20px ;
color:   #6e6e6e ;
display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;

}


.therdparts h1 
{
  margin-top: 50px ;
  margin-bottom: 10% ;
  font-size: 22px;
  text-align: center; 
  font-weight: 500;
  line-height: 1.46;
  color: #232b51;
  font-family: 'Ubuntu', sans-serif ;

} 

.timeline-content h2 {
 padding: 5px;
 margin-top: 10px;
  font-size: 17.5px;
  font-weight: 500;
  line-height: 1.46;
  color: #232b51;
  font-family: 'Ubuntu', sans-serif ;
  text-align: center; 

    
}


.timeline-content p {
 
  font-size: 14px; 
  font-weight: 500;
  line-height: 1.46;
  color: #3f424ec9;
  text-align: center; 

  font-family: 'Ubuntu', sans-serif ;
}
.timeline-content button {
  background-color: #54b2b4 ;
  color: #fff ;
  border-radius: 10px ;
  border: none ;
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 5px ;
  padding-bottom: 5px ;
  font-family: 'Ubuntu', sans-serif ;
  position: relative ;
  margin-top: 20px;
  right: -50%;
}


.timeline-content button:hover {
  background-color: #287475 ;
  color: #fff  ; 
}


.backgroundContact {
  background-image: url('./../../img/contact.png') ;
  background-repeat: repeat ;
  background-attachment: fixed;
  margin-top: 0px;
  padding-top: 0;
  background-size: cover;



}

 
.cont h1  
{
  margin-top: 30px ;
  margin-bottom: 30px ;
  font-size: 35px;
  text-align: center; 
  font-weight: 500;
  line-height: 1.46;
  color: #232b51;
  font-family: 'Ubuntu', sans-serif ;

}
.cont h1::after 
{
  content: '' ;
  background: #232b51 ;
  display: block;
  height: 3px ;
  width: 10% ;
  margin: 10px auto 1px ; 

    }


    
.cont h2  
{
  margin-top: 15px ;
  margin-bottom: 15px ;
  font-size: 22px;
  font-weight: 500;
  line-height: 1.46;
  color: #232b51;
  font-family: 'Ubuntu', sans-serif ;

}


    
.cont h3 
{
  margin-top: 15px ;
  margin-bottom: 15px ;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.46;
  color: #232b51;
  font-family: 'Ubuntu', sans-serif ;

}


.frome {
  background-color: rgba(250, 250, 250, 0.514) ;
  padding: 20px;
  border-radius: 7px;   
} 
.from {   
  padding-bottom: 50px;
} 

.pital {
  font-size: 10px ;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
}
.pt2 {
  background: #06a5cc;
  background: -webkit-linear-gradient(top left, #06a5cc 0%, #54b2b4 100%);
  background: -moz-linear-gradient(top left, #06a5cc 0%, #54b2b4 100%);
  background: -o-linear-gradient(top left, #06a5cc 0%, #54b2b4 100%);
  background: linear-gradient(to bottom right, #06a5cc 0%, #54b2b4 100%);
        
    padding: 13% ;
    color: white ;
    border-radius: 10px; 
}
 
.icons {
 
  color: white ;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(252, 252, 252);
  font-size: 30px;
  padding: 5px;
  border-radius: 50% 50% 50% 50%; 
  margin-right: 5px;
  margin-bottom: 5px;
  
}
 
   
  
input-group>.custom-file, .input-group>.custom-select, .input-group>.form-control, .input-group>.form-control-plaintext {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  /* min-width: 0; */
  margin-bottom: 0; 

  background: none;
  border-color: #06a5cc;
     border-radius: 10px;
       
}


input-group>.custom-file, .input-group:hover{
 
  /* min-width: 0; */
  margin-bottom: 0; 
  background: #06a4cc7c;
  border-color: non  ;
     border-radius: 10px;
       
}

 
 .butnn {
    
  background: -webkit-linear-gradient(top left, #06a5cc 0%, #54b2b4 100%);
  background: -moz-linear-gradient(top left, #06a5cc 0%, #54b2b4 100%);
  background: -o-linear-gradient(top left, #06a5cc 0%, #54b2b4 100%);
  background: linear-gradient(to bottom right, #06a5cc 0%, #54b2b4 100%);
      
  border: none ;
  border-radius: 15px;
  padding : 10px 100px 10px 100px;
  color: white;
 }




  
  .butnn  div {
  transition: transform 0.8s;
}

.butnn:hover div {
  transform: translateX(10px);
 

}.butnn:hover  {
   

  background: -webkit-linear-gradient(top left, #03708b 0%, #3b8081 100%);
  background: -moz-linear-gradient(top left, #03708b 0%, #3b8081 100%);
  background: -o-linear-gradient(top left, #03708b 0%, #3b8081 100%);
  background: linear-gradient(to bottom right, #03708b 0%, #3b8081 100%);

}
  .butnn::after {
  content: "";
  width: 100%;
  z-index: -1;
  position: absolute;
  height: 100%;
  top: 7px;
  left: 7px;
}
 


.backgroundOffres {
  background-image: url('./../../img/bgOffres.jpg') ;
  background-repeat: repeat ;
  background-attachment: fixed;
  margin-top: 0px;
  padding-top: 0;
  background-size: cover;



}





.testimotionals {
  width:310px;
   display:inline-block;
    margin-left:50px;
    margin-top:50px ;
    margin-bottom: 30px ;
    
  }
  
  
  .testimotionals .card {
    
    position:relative;
    overflow:hidden;
    width:310px;
    height: 165px;
    margin:0 auto;
    
    background:rgb(255, 255, 255);
    padding:10px;
    box-sizing:border-box;
    text-align:center;
    box-shadow:0 10px 40px rgba(0, 0, 0, 0.233)
  }
  .testimotionals .card .layer {
    z-index:1;
    position:absolute;
    top:calc(100% - 2px);
    height:100%;
    width:100%;
    left:0;
    background:linear-gradient(to left , #7ab0e2, #54b2b4 );
    transition:0.5s;
    
  }
  
  .testimotionals .card .content {
    z-index:2; 
    position:relative;
  }
  
  .testimotionals .card:hover  .layer{
    top:0;
  }
  
  .testimotionals .card .content p {
    font-size:14px;
    line-height:24px;
    color: #33384e;
    
    font-family: 'Ubuntu', sans-serif ;
    
  }  
  .testimotionals .card .content .details h2 {
    margin-top: 10px;
    margin-bottom: 25px;
  font-size:16px;
  color:#232b51;
  
  font-family: 'Ubuntu', sans-serif ;
  }
  .testimotionals .card:hover .content .details h2  {
  font-size:18px;
    transition:0.5s;
    color: #fff    ;

  }
  .testimotionals .card:hover .content .details p {
    color:white;
    position:relative
  }
  

  .ligthbtn { 
    border-color: #54b2b4;
    color: #52a0a1    ;
    border-width: 2px;
    border-radius: 100px ;
    padding: 8px 20px 8px 20px ;
    background-color: transparent;
    font-size: 13px; 
    margin: 10px;
    
    font-family: 'Ubuntu', sans-serif ;     
    


  }


  .demandeBTN { 
    border-color: #54b2b4;
    color: #52a0a1    ;
    border-width: 2px;
    border-radius: 100px ;
    padding: 8px 20px 8px 20px ;
    background-color: transparent;
    font-size: 19px; 
    margin-top: 24px;
    
    font-family: 'Ubuntu', sans-serif ;     
    


  }

  .demandeBTN:hover { 
    border-color: #52a0a1;
    color: #232b51;
     
    box-shadow:0 1px 4px rgba(0, 0, 0, 0.13)

  }

  .testimotionals .card:hover .content .details .ligthbtn { 
    border-color: rgb(41, 91, 114);
    color: rgb(24, 59, 75);
    border-radius: 108px ; 

  }

  
  .testimotionals .card:hover .content .details .ligthbtn:hover { 
    border-color: rgb(250, 250, 250);
    color: rgb(249, 250, 250);
     
    box-shadow:0 1px 4px rgba(0, 0, 0, 0.13)

  }

  .imgExp {
     width : 100px ; 
     position: relative;
     margin-bottom: 30px;
       
  }
  .climg{
    margin-bottom: 50px;
  }

  .Clinets
  {
    margin-top: 10%;
    margin-bottom: 10%;
  }
 

  .ApprocheFrom { 
    background: #06a5cc;
    background: -webkit-linear-gradient(top left, #06a5cc 0%, #54b2b4 100%);
    background: -moz-linear-gradient(top left, #06a5cc 0%, #54b2b4 100%);
    background: -o-linear-gradient(top left, #06a5cc 0%, #54b2b4 100%);
    background: linear-gradient(to bottom right, #06a5cc 0%, #54b2b4 100%);
          
      padding-top: 6% ;
      padding-bottom: 6% ;
      color: white ; 
      font-size: 18px;
      text-align: center; 
  }

  .linke {
    color: rgb(12, 22, 37) ;
    text-decoration: underline;
  }
  .linke:hover{
    color: rgb(253, 253, 253) ;
    text-decoration: underline;
    font-size: 20px;
    transition:0.5s;
    

  }

  .linkeButton {
    color: white; 

  }
  .linkeButton:hover{
    color: rgb(253, 253, 253) ;
    text-decoration: none ;

  }

  .theerd {
    margin-top: 30px ;
    padding-top: 30px;
  }
 
  .partrsque img   {

    margin-top: 20px ;
width: 100%;
  }
  .partrsque p {
    text-align: left ;
    font-size: 16px ;
    color:   #6e6e6e ;
    display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
    
    }
.RefMateruelRisques {

  background-color: #132043  ;
  color: white ;
  margin-top: 5%;
}





.RefMateruelRisques h1 {
  font-size: 18px;
 }

 .RefMateruelRisques p {
  font-size: 15px;
 }

 
 .PREVEX {
  text-align: center ;
  width: 50% ;
  filter: grayscale(100%);
  margin: 30px;
  margin-top: 17%;
   
 }
 .PREVEX:hover {
  text-align: center ;
  filter: grayscale(0);
  transition: 0.3s;
   
 }


 .test {
 padding: 30px;
 margin: 30px;
margin-top: 47px;

}

 .item p{
    
      font-size: 20px ;
      color:   #181717 ;
      display: block;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      margin-left: 0;
      margin-top: 0;
    
}









.autreOffer h1 {
  overflow: hidden;
  text-align: center;
  font-size: 19px ;
  color:   #181717 ;
  display: block; 
  margin-top: 10% ;
  margin-bottom : 1% ;
}

.autreOffer h1:before  {
  background-color: #7e7e7ea2 ;
  content: "";
  display: inline-block;
  height: 0.5px;
  position: relative;
  vertical-align: middle;
  width: 40%;
}
.autreOffer  h1:after {
  background-color: #7e7e7ea2 ;
  content: "";
  display: inline-block;
  height: 0.5px;
  position: relative;
  vertical-align: middle;
  width: 40%;
}

.autreOffer 
  h1:before {
  right: 0.5em;
  margin-left: -50%;
}

.autreOffer  
  h1:after {
  left: 0.5em;
  margin-right: -50%;
}

.visite {

  margin-top: 10%;
background-color: #06a5cc ;
color: white;
padding: 5%;
}
.titreVisite {
  margin-bottom: 20px;
}

.titreVisite h1 {

  padding: 10px;
 color: white;
 text-align: center;
 font-size: 25px; 
 }
 .titreVisite h1::after 
 {
  content: '' ;
  background: #ffffff ;
  display: block;
  height: 3px ;
  width: 10% ;
  margin: 20px auto 5px ; 

     }

     
.visite h2 {

 padding: 0;
color: white;
text-align: center;
font-size: 20px;
margin-bottom: 10px;
}

.visite p{

 
    text-align: center ;
    color: rgb(245, 245, 245);
    font-size: 16px;
    transition: 0.5s;
    margin: 20px;
    }

     

    .EStt p {

      padding-right: 20px;
      text-align: left;
      font-size: 16px;
    }




    .secands-ligthe h1  {
    margin-top: 30px ;
    margin-bottom: 30px ;
    font-size: 22px;
    text-align: center; 
    font-weight: 500;
    line-height: 1.46;
    color: #ffffff;
    font-family: 'Ubuntu', sans-serif ;

  }

  .secands-ligthe h1::after 
  {
  	content: '' ;
  	background: #ffffff ;
  	display: block;
  	height: 3px ;
  	width: 10% ;
  	margin: 20px auto 5px ; 

  	  }

      .testmeni {
        padding: 20px;
        padding-bottom: 40px;
      }

      
      .testmeni h2 {
        margin-top: 10px; 
      
        font-size: 19px;
        color: rgba(255, 255, 255, 0.973);
      }



      .testmeni p 
      {
        
       margin-left: 15px;
        margin-top: 10px; 
        margin-bottom : 10px; 
        color: rgba(255, 255, 255, 0.945);
    
          }








.footer {

background-color: rgba(243, 243, 243, 0.89);
padding: 2%;
margin-top: 10%;

}

.titreFooter {


    font-family: 'Ubuntu', sans-serif ;
  font-size: 1em;
}


.footer a {

  font-family: 'Ubuntu', sans-serif ;
  font-size: 0.9em;
  color: #000;
  margin: 2%;
}

.buttombutter p {
 
  text-align: center;
margin-top: 1em;
padding-bottom: 0;
margin-bottom: 0;
font-size: 0.9em;
}

.logoFotter {
  width:90% ;
  padding: 0 ;
  margin: 0; 
}
 .iconsFooter {

  background-color: #ffffff  ;
  color: #16328b; 
  font-size: 30px;
  padding: 5px;
  border-radius: 5px ;
 }


 .titreSpecilat h2 {

  margin-top: 50px ;
  margin-bottom: 10% ;
  font-size: 22px;
  text-align: center; 
  font-weight: 500;
  line-height: 1.46;
  color: #232b51;
  font-family: 'Ubuntu', sans-serif ;
 }

.cardAdmin{
  background: #06a5cc;
  background: -webkit-linear-gradient(top left, #06a5cc 0%, #54b2b4 100%);  
  padding: 30px;
  width: 200px;
  text-align: center;
  color: white;
  border-radius: 5px;
  margin: 20px;
  box-shadow: 5px 10px 10px #88888870;
}

.cardAdmin:hover {
  background: -webkit-linear-gradient(top left, #13bbe6 0%, #72f1f3 100%);  
  padding: 30px;
  width: 200px;
  text-align: center;
  color: white;
  border-radius: 5px;
  margin: 20px;
  box-shadow: 5px 10px 10px #88888870;
  transition: 0.9s;
}
 
.cardAdmindiv a{
  
  text-decoration: none; /* remove underline */
	color: inherit; /* remove blue */
}

.cardAdmindiv a:hover, a:focus, a:active {
  text-decoration: none;
  color: inherit;
}

/* les card du Article */ 

.imagecard {  
  margin-left: 30px;
 width: 300px;
} 


/*/----- Fromuler admin  pour ajouter un blog /*/

.addblogF {
  margin: 30px;
  padding: 20px;
  font-family:Georgia, 'Times New Roman', Times, serif;
}
.addblogFh1 {
text-align: center;
}

.HomeAdmin {
  margin-top: 5%;
}


/*############ button of lire in parte admin ####################*/

.btnLir {
  margin: 20px;
  margin-top: 30px;
  text-align: center;
}