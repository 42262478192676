 
.iframely-embed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
  background: white;
}
 

#app {
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: center;
  width: 100%;
  background: #c6f6ffa6;
  color: #232b51 ;
  margin-bottom: 30px
}

 

@import url("https://fonts.googleapis.com/css2?family=Prata&display=swap");

.title {
  padding-left: 1em;
  grid-column: 1 / -1;
  grid-row: 1;

  font-family: "Prata", serif;
  font-size:4vw;
  width: 100%;
  z-index: 2;

  // start

  > .title-inner {
    display: inline-block;
  }
}

@keyframes text-clip {
  from {
    clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%);
  }
  to {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
}

@keyframes outer-left {
  from {
    transform: translateX(50%);
  }
  to {
    transform: none;
  }
}

@keyframes inner-left {
  from {
    transform: translateX(-50%);
  }
  to {
    transform: none;
  }
}

.cafe,
.mozart {
  animation: outer-left 1s 1s cubic-bezier(0.5, 0, 0.1, 1) both;
  // outline: 1px solid red;
}

// [class*='inner'] {
//   outline: 1px solid blue;
// }

.title-inner {
  display: inline-block;
  animation: inner-left 1s 1s ease both;
}

.cafe-inner {
  display: inline-block;
  animation: inner-left 1s 1s ease both,
    text-clip 1s 0s cubic-bezier(0.5, 0, 0.1, 1) both;
}

.mozart-inner {
  animation: text-clip 1s 0s cubic-bezier(0.5, 0, 0.1, 1) both;
}

 

.title {
  animation: outer-left 1s 1s ease both;
}

.cafe {
  // start
  > .cafe-inner {
    display: inline-block;
  }
}

.mozart {
  display: inline-block;
}

.image {
  grid-row: 1;
  grid-column: 2; 
  

  animation: image-in 1s cubic-bezier(0.5, 0, 0.1, 1) 2s backwards;

  @keyframes image-in {
    from {
      clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    }
    to {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }
  }

  img {
    display: block;
    width: 100%;
    opacity: 0.5;
  }
  p {
    font-size: 20px;
    color: rgb(3, 3, 3);
    opacity:0.9;
  }
}


.donc {
  
  

  animation:text-clip 1s cubic-bezier(0.5, 0, 0.1, 1) 2.5s backwards;

  @keyframes text-clip {
    from {
      clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    }
    to {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }
  }
  p {
    font-size: 20px;
    color: rgb(3, 3, 3);
    opacity:0.9;
    text-align: center;
  }
   
}


 